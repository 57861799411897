<template>
  <section class="section contacts">
    <h2>{{ $t("contacts_section.title") }}</h2>
    <div class="contacts-container">
      <div class="contacts-info">
        <div v-for="(block, key) in officeContact.blocks">
          <p class="text-h4">{{ $t(`contacts_section.${key}`) }}:</p>
          <p
            v-for="(string, index) in block"
            :key="`${key}-${index}`"
            v-html="string"
          >
          </p>
        </div>
      </div>
      <div class="contacts-map">
        <OfficeMap :center="officeContact.officeMarker" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { PropType } from "vue";

import OfficeMap from "~/components/map/OfficeMap.vue";
import { OfficeContact } from "~/types/content";

export default defineNuxtComponent({
  components: { OfficeMap },
  props: {
    officeContact: {
      type: Object as PropType<OfficeContact>,
      required: true,
    },
  },
});
</script>
